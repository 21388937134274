exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bathroom-js": () => import("./../../../src/pages/bathroom.js" /* webpackChunkName: "component---src-pages-bathroom-js" */),
  "component---src-pages-closet-js": () => import("./../../../src/pages/closet.js" /* webpackChunkName: "component---src-pages-closet-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kitchen-js": () => import("./../../../src/pages/kitchen.js" /* webpackChunkName: "component---src-pages-kitchen-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-wall-units-js": () => import("./../../../src/pages/wall-units.js" /* webpackChunkName: "component---src-pages-wall-units-js" */)
}

